<div class="relative">
  <div class="mt-3 text-black dark:!text-white">
    <a routerLink="/admin" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
    <h1 class="mt-3 text-2xl font-bold">Admin portal</h1>
    <div class="flex gap-2 mt-2">
      <a (click)="togglePage(0)" class="text-xs text-blue-600 dark:text-blue-300 cursor-pointer">Upcoming games</a>
      <span class="text-xs">|</span>
      <a (click)="togglePage(1)" class="text-xs text-blue-600 dark:text-blue-300 cursor-pointer">Past games</a>
      <span class="text-xs">|</span>
      <a (click)="togglePage(3)" class="text-xs text-blue-600 dark:text-blue-300 cursor-pointer">Maps by state</a>
      <span class="text-xs">|</span>
      <a (click)="togglePage(4)" class="text-xs text-blue-600 dark:text-blue-300 cursor-pointer">Checks</a>
    </div>
    <div>
      <div *ngIf="pages[0]">
        <h2 class="text-xl font-bold mt-4 mb-2">Upcoming games</h2>
        <p class="mb-3 text-xs">Click on images to enlarge. Tests checks whether seven other landmark names and six
          city names are loaded in.</p>
      </div>
      <div *ngIf="pages[1]">
        <h2 class="text-xl font-bold mt-4 mb-2">Past games</h2>
        <p class="mb-3 text-xs">Click on images to enlarge. Tests checks whether seven other landmark names and six
          city names are loaded in.</p>
      </div>
      <div *ngIf="pages[3]">
        <div *ngIf="gameByState$ | async as game" class="flex flex-wrap gap-1 items-center mb-4">
          <h2 class="text-xl font-bold mt-4 mb-2">Maps by state</h2>
          <p class="mb-3 text-xs w-full">Maps show the capital cities of each state</p>
          <div *ngFor="let state of states">
            <a class="cursor-pointer" (click)="changeMap(state.name)">
              <span class="text-xs p-1 bg-slate-200 dark:bg-slate-500 rounded-sm">{{ state.name }}</span>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="!pages[3]" class="flex flex-col justify-start">
        <div *ngIf="pages[2]">
          <h2 class="text-xl font-bold mt-4 mb-2">{{ (gameByState$ | async)?.name }}</h2>
        </div>
        <div *ngFor="let game of games" class="w-full mb-12 bg-slate-200 dark:bg-slate-500 p-3 rounded-md shadow-md"
          [ngClass]="{'bg-red-200' : game.past}">
          <h3 *ngIf="game.past" class="text-sm mb-2">This game has already been played</h3>
          <h3 *ngIf="showUpcoming" class="font-bold text-lg">Game {{ game.gameNumber }}: {{game.state}} ({{
            game.code.toUpperCase() }}) - {{ game.date }} - {{ game.photoCode }}</h3>
          <h3 *ngIf="!showUpcoming" class="font-bold text-lg mb-2">{{ game.stateGame }}: {{ game.state }} - {{ game.date
            }} - Game: {{game.gameNumber}}</h3>
          <div class="mb-2">
            <a class="mb-4 text-xs text-blue-600 dark:text-white font-bold" [href]="game.gameUrl">Play this game</a>
            <span class="text-sm">&nbsp;|&nbsp;</span>
            <a class="mb-4 text-xs text-blue-600 dark:text-white font-bold cursor-pointer"
              (click)="copyToClipboard(game.gameUrl)">Copy game URL</a>
          </div>
          <div class="grid grid-cols-2 gap-2">
            <div>
              <h4 class="font-bold text-xs mb-1">Main round</h4>
              <img class="cursor-pointer" (click)="enlargeImage(game.mainImage)" [ngSrc]="game.mainImage" width="992"
                height="720">
            </div>
            <div>
              <h4 class="font-bold text-xs mb-1">City round - {{ game.cityName}}</h4>
              <img class="cursor-pointer" (click)="enlargeImage(game.cityImage)" [ngSrc]="game.cityImage" width="992"
                height="720">
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 mt-2">
            <div>
              <h4 class="font-bold text-xs mb-1">Landmark round - {{ game.landmarkName }}</h4>
              <img class="cursor-pointer" (click)="enlargeImage(game.landmarkImage)" [ngSrc]="game.landmarkImage"
                width="992" height="720">
            </div>
            <div>
              <h4 class="font-bold text-xs mb-1">Other landmark images</h4>
              <div class="grid grid-cols-2 gap-1">
                <div *ngFor="let landmark of game.otherLandmarks">
                  <img class="cursor-pointer"
                    (click)="enlargeImage('./assets/countries/'+landmark.code.toLowerCase()+'/landmark'+landmark.number+'.webp')"
                    [ngSrc]="'./assets/countries/'+landmark.code.toLowerCase()+'/landmark'+landmark.number+'.webp'"
                    width="992" height="720">
                </div>
              </div>
            </div>
          </div>
          <p class="font-bold text-sm mt-3 mb-1">Links</p>
          <div class="flex flex-wrap gap-1 text-blue-600">
            <a class="text-sm" [href]="game.mainImageLink" target="_blank">Main image |</a>
            <a class="text-sm" [href]="game.mainWikiLink" target="_blank">Main wikipedia |</a>
            <a class="text-sm" [href]="game.landmarkImageLink" target="_blank">Landmark image |</a>
            <a class="text-sm" [href]="game.landmarkWikiLink" target="_blank">Landmark wikipedia |</a>
            <a class="text-sm" [href]="game.cityImageLink" target="_blank">City image |</a>
            <a class="text-sm" [href]="game.cityWikiLink" target="_blank">City wikipedia</a>
          </div>
          <p class="font-bold text-sm mt-3 mb-1">Landmark multiple choice options</p>
          <div class="flex flex-wrap gap-1">
            <p class="text-xs p-1 bg-green-100 dark:bg-green-400 rounded-md">{{ game.landmarkName }}</p>
            <p *ngFor="let landmark of game.landmarkPlaces"
              class="text-xs p-1 bg-slate-100 dark:bg-slate-700 rounded-md">{{ landmark }}</p>
          </div>
          <p class="font-bold text-sm mt-3 mb-1">Has city round?</p>
          <p class="text-sm">{{ game.hasCityRound }}</p>
          <p class="font-bold text-sm mt-3 mb-1">City multiple choice options</p>
          <div class="flex flex-wrap gap-1">
            <p *ngFor="let city of game.allCities" class="text-xs p-1 bg-slate-100 dark:bg-slate-700 rounded-md"
              [ngClass]="{'!bg-green-100 dark:!bg-green-400' : city === game.cityName}">{{ city }}</p>
          </div>
          <div class="mt-3">
            <p class="font-bold text-sm">Capital City: <span class="text-lg">{{ game.capitalCity }}</span></p>
          </div>
          <h3 class="font-bold text-base mt-3 mb-1">Tests</h3>
          <div class="flex gap-3 text-xs">
            <div *ngIf="game.mainGameName">
              <p class="font-bold text-sm">Main round has name ✅</p>
            </div>
            <div *ngIf="!game.mainGameName">
              <p class="font-bold text-sm">Main round has name ❌</p>
            </div>
            <div *ngIf="game.landmarkCheck">
              <p class="font-bold text-sm">Landmarks ✅</p>
            </div>
            <div *ngIf="!game.landmarkCheck">
              <p class="font-bold text-sm">Landmarks ❌</p>
            </div>
            <div *ngIf="game.cityCheck">
              <p class="font-bold text-sm">Cities ✅</p>
            </div>
            <div *ngIf="!game.cityCheck">
              <p class="font-bold text-sm">Cities ❌</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="pages[3]">
        <h4 class="font-bold mb-2">{{ statePlusCode }} - {{ capital }}</h4>
        <div class="border-2 border-slate-400 rounded-md" *ngIf="showMap">
          <app-svg-map [admin]="true" [countryCode]="stateCode"></app-svg-map>
        </div>
      </div>
    </div>
    <div *ngIf="pages[4]">
      <h2 class="text-xl font-bold mt-4 mb-2">Data Checks</h2>
      <p class="mb-3 text-xs">Running checks on the data to make sure everything is there.</p>

      <h4 class="text-lg font-bold mt-4 mb-2">Test 1</h4>
      <p class="mb-3 text-xs">Check if each state has a main name, image link and wikipedia link</p>
      <p class="mb-3 text-xs">Status <span *ngIf="mainCheck">✅</span><span *ngIf="!mainCheck">❌</span></p>

      <h4 class="text-lg font-bold mt-4 mb-2">Test 2</h4>
      <p class="mb-3 text-xs">Check if each state has a city name, image link and wikipedia link</p>
      <p class="mb-3 text-xs">Status <span *ngIf="cityCheck">✅</span><span *ngIf="!cityCheck">❌</span></p>

      <h4 class="text-lg font-bold mt-4 mb-2">Test 3</h4>
      <p class="mb-3 text-xs">Checking landmark round. Checking if all answers are loaded in and there are no duplicates</p>
      <p class="mb-3 text-xs">Status <span *ngIf="landmarkCheck">✅</span><span *ngIf="!landmarkCheck">❌</span></p>

      <h4 class="text-lg font-bold mt-4 mb-2">Test 4</h4>
      <p class="mb-3 text-xs">Checking perm dates. Checking if all perms dates are in consectutive order with no missing dates
      </p>
      <p class="mb-3 text-xs">Status <span *ngIf="dateCheck">✅</span><span *ngIf="!dateCheck">❌</span></p>
      
      <h4 class="text-lg font-bold mt-4 mb-2">Errors</h4>
      <div *ngFor="let error of errors">
        <p class="mb-3 text-xs">{{error}}</p>
      </div>

    </div>
  </div>
</div>
