<div class="lg:bg-gray-100 lg:dark:bg-[#020617] flex flex-col w-full h-full justify-center items-center"
  *ngIf="user$ | async as user">
  <app-top-banner-ad></app-top-banner-ad>
  <div class="hidden lg:flex"><holiday-banner></holiday-banner></div>
  <div
    class="footer-wrap relative w-full h-full flex flex-col max-w-[568px] justify-start items-center bg-gray-50 dark:!bg-slate-900 lg:box-content lg:px-12 lg:shadow-2xl"
    *ngIf="state$ | async as state">
    <div class="w-full relative lg:!pt-0" [ngClass]="{
        'pt-24' : state.topAdOpen && !user?.subscription?.isPremium,
        'pt-4' : !state.topAdOpen}">
        <div class="lg:hidden"><holiday-banner></holiday-banner></div>
        <div class="container">
        <div class="absolute z-50">
          <app-alert class="alert" *ngIf="state.showAlert"></app-alert>
        </div>
        <app-header></app-header>
        <div *ngIf="archive$ | async as archive">
          <div *ngIf="archive.isArchiveGame"
            class="flex flex-col items-center justify-center mt-2 bg-slate-200 px-2 py-1 rounded-md shadow-md">
            <p class="text-sm font-bold">Archive mode</p>
            <a class="cursor-pointer text-blue-600 text-xs" (click)="returnToToday()">Return to today's game</a>
          </div>
        </div>
        <router-outlet></router-outlet>
        <div class="sidebar-ad-desktop flex flex-col items-center">
          <a routerLink="/remove-ads" class="text-xs text-blue-600 cursor-pointer mb-1"
            *ngIf="!user.subscription?.isPremium">Remove ads</a>
          <div id="adngin-sidebar_left-0"></div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<br>
<!--
<pre *ngIf="state$ | async as state">
  {{ state | json   }}
</pre> -->
<!--
<pre *ngIf="stats$ | async as state">
  {{ state | json   }}
</pre> -->
<!-- <pre *ngIf="user$ | async as user">
  {{ user | json   }}
</pre>
 -->
