import { Component } from '@angular/core';

@Component({
  selector: 'holiday-banner',
  templateUrl: './holiday-banner.component.html',
  styleUrls: ['./holiday-banner.component.scss'],
})
export class HolidayBannerComponent {
  show: boolean = false;

  constructor() {
    const checkSalePeriod = () => {
      const now = Date.now();
      const saleStart = 1734307200000;
      const saleEnd = 1735084799000;
      return now > saleStart && now < saleEnd;
    };

    this.show = checkSalePeriod();
  }
}
