import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { Banner } from 'src/app/interfaces/Banner';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { Country, Game, Stats } from 'src/app/interfaces/interfaces';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CountriesService } from 'src/app/services/countries.service';
import { DataService } from 'src/app/services/data.service';
import { LinksService } from 'src/app/services/links.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit {
  state$: Observable<Game>;
  stats$: Observable<Stats>;
  user$: Observable<UserDetails>;
  banner$: Observable<Banner>;
  places: Country[];

  constructor(
    private data: DataService,
    private countries: CountriesService,
    private title: Title,
    private route: ActivatedRoute,
    private user: UserService,
    private router: Router,
    private links: LinksService,
    private analytics: AnalyticsService
  ) {
    this.state$ = this.data.getState();
    this.places = this.countries.getCountries();
    this.title.setTitle('WhereTaken');
    this.stats$ = this.user.getStats();
    this.user$ = this.user.getUserDetails();
    this.banner$ = this.links.banner$;
    this.links.getBannerAndLinks();
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.pipe(take(1)).subscribe((val) => {
      if (val['game'] && val['date']) {
        this.user$.pipe(take(1)).subscribe((user) => {
          if (user?.subscription?.isPremium) {
            this.data.setArchiveGame(val['game'], val['date']);
            this.user.setArchiveGame(true);
          } else {
            this.data.setArchiveGame(val['game'], val['date']);
            this.user.setArchiveGame(true);
          }
        });
      }
    });
    await this.data.createGame();

    // needs to wait until createGame completes
    this.data.updatePreviousPage('/');
  }

  linkClicked() {
    this.analytics.sendEvent('blue_box_link_clicked', {
      event_category: 'The link in the blue box was clicked',
      event_label: 'Blue box link clicked',
      value: 1,
    });
  }
}
